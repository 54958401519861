<div
  (mouseenter)="collapseOpenSidenav()"
  (mouseleave)="collapseCloseSidenav()"
  [class.collapsed]="collapsed"
  [class.open]="collapsed && (collapsedOpen$ | async)"
  class="vex-sidenav flex flex-col">
  <div class="vex-sidenav-toolbar__container">
    <div class="sidenav-toolbar flex-none flex items-start">
      <img
        [src]="collapsed
        ? (collapsedOpen$ | async) ? (imageUrl$ | async) : (collapseImageUrl$ | async)
        : (imageUrl$ | async)
        "
        alt="Logo"
        class="select-none flex-none" />

      <!-- <h2 class="vex-sidenav-toolbar__headline flex-auto" *ngIf="title$ | async">{{ title$ | async }}</h2> -->
    </div>

    <div *ngIf="searchVisible$ | async" class="vex-sidenav-search__container">
      <div
        (click)="openSearch()"
        class="vex-sidenav-search relative"
        matRipple
        matRippleColor="var(--vex-sidenav-item-ripple-color)">
        <mat-icon
          class="flex-none vex-sidenav-search__icon"
          svgIcon="mat:search"></mat-icon>
        <div class="flex-auto vex-sidenav-search__placeholder">
          Quick Search
        </div>
        <div class="flex-none vex-sidenav-search__keybind">Ctrl K</div>
      </div>
    </div>
    <!-- <div class="vex-sidenav-toolbar__divider"></div> -->
  </div>

  <vex-scrollbar class="flex-1 flex flex-col">
    <div class="sidenav-company-name">
      <span class="w-9 h-9 p-2 ml-1 mr-3 text-center rounded bg-ochraceus">{{(org$ | async) | userInitials}}</span> <span class="sidenav-company-label">{{org$ | async}}</span>
    </div>
    <div class="pt-5 flex-1">
      <rms-sidenav-item
        *ngFor="let item of items$ | async; trackBy: trackByRoute"
        [item]="item"
        [level]="0"></rms-sidenav-item>
    </div>
  </vex-scrollbar>

  <div class="vex-sidenav-toolbar__container" *ngIf="showCollapsePin$ | async">
    <button
      (click)="toggleCollapse()"
      class="vex-sidenav-toolbar__collapse-toggle -mr-4 leading-none hidden lg:block flex-none"
      mat-icon-button
      type="button">
      <mat-icon
        *ngIf="!collapsed"
        class="icon-xs"
        svgIcon="remix:Arrows/expand-left-line"></mat-icon>
      <mat-icon
        *ngIf="collapsed"
        class="icon-xs"
        svgIcon="remix:Arrows/expand-right-line"></mat-icon>
    </button>
    <span class="text-light">Collapse</span>
  </div>

  <div
    *ngIf="userVisible$ | async"
    class="vex-sidenav-user__container flex-none">
    <div
      #userProfileMenuOriginRef
      (click)="openProfileMenu(userProfileMenuOriginRef)"
      [class.vex-sidenav-user--open]="userMenuOpen$ | async"
      class="vex-sidenav-user flex items-center"
      matRipple
      matRippleColor="var(--vex-sidenav-item-ripple-color)">

      <span class="vex-sidenav-user__image text-center bg-caeruleus p-2">{{(userName$ | async) | userInitials}}</span>
      <!-- <img
           alt="User Avatar"
           class="vex-sidenav-user__image flex-none"
           src="../../../../assets/img/avatars/1.jpg" /> -->
      <div class="vex-sidenav-user__content flex-auto">
        <div class="vex-sidenav-user__title ">{{userName$ | async}}</div>
        <div class="vex-sidenav-user__subtitle"></div>
      </div>
      <mat-icon
        class="vex-sidenav-user__dropdown-icon flex-none"
        svgIcon="mat:arrow_drop_down"></mat-icon>
    </div>
  </div>
</div>
