import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest
} from '@angular/common/http';
import { Observable } from 'rxjs';

import { EXCLUDED_URL_PATTERNS } from '../excluded-domains.const';
import { AuthService } from 'src/app/domains/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class TokenInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService) { }

  private shouldSkip(url: string): boolean {
    return EXCLUDED_URL_PATTERNS.some((pattern: RegExp) => pattern.test(url))
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (this.shouldSkip(request.url)) {
      return next.handle(request);
    } else {
      const authReq = request.clone({
        setHeaders: {
          authorization: `Bearer ${this.authService.getToken()}`
        }
      });
      return next.handle(authReq);
    }
  }
}
