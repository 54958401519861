import { inject } from "@angular/core";
import { Router } from "@angular/router";

import { AuthService } from "./auth.service";
import { NotificationService } from "src/app/core/notification/notification.service";

export function authGuard() {
  const router = inject(Router);
  const auth = inject(AuthService);
  const notification = inject(NotificationService);

  if (auth.isAuthenticated() && !auth.isTokenExpired()) {
    return true;
  } else {
    // logout and notify if we are not in auth routes
    auth.logout();
    if (!['/forgot-password', '/reset-password', '/login'].includes(router.url)) {
      notification.error(
        'auth.expired',
        'common.ok'
      );
    }
    return router.createUrlTree(["/login"]);
  }
}
