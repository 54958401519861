import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LoggerService {
  private logLevels: Record<string, number> = {
    debug: 0,
    info: 1,
    warn: 2,
    error: 3,
  };

  private currentLogLevel: number;

  constructor() {
    this.currentLogLevel = environment.production ? this.logLevels['warn'] : this.logLevels['debug'];
  }

  private shouldLog(level: string): boolean {
    return this.logLevels[level] >= this.currentLogLevel;
  }

  private logLabel(name: string, entity: string): string {
    return `[${name}] [${entity}] ${new Date().toISOString()}`;
  }

  private getStack(): string {
    return new Error().stack ?? '';
  }

  debug(payload: any, entity: string): void {
    if (this.shouldLog('debug')) {
      console.debug(this.logLabel('DEBUG', entity), payload);
    }
  }

  info(payload: any, entity: string): void {
    if (this.shouldLog('info')) {
      console.info(this.logLabel('INFO', entity), payload);
    }
  }

  warn(payload: any, entity: string): void {
    if (this.shouldLog('warn')) {
      console.warn(this.logLabel('WARN', entity), payload);
    }
  }

  error(payload: any, entity: string): void {
    if (this.shouldLog('error')) {
      console.error(this.logLabel('ERROR', entity), payload, this.getStack());
    }
  }
}
