<div class="p-8">
  <rms-close-button class="absolute top-4 right-4" (click)="dismiss()" mat-dialog-close></rms-close-button>

  <div class="flex flex-row items-center mb-6">
    <div class="flex flex-col">
      <div class="text-xl font-bold mb-3" mat-dialog-title>{{title | translate}}</div>
      <div class="text-lg text-normal" [innerHTML]="message | translate"></div>
    </div>
  </div>

  <div class="flex flex-row items-center justify-between">
    <button
      (click)="cancel()"
      mat-stroked-button
    >{{'common.cancel' | translate}}</button>

    <button
      (click)="ok()"
      color="primary"
      mat-raised-button
    >{{'common.ok' | translate}}</button>
  </div>

</div>
