import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of, tap, switchMap, catchError } from 'rxjs';

import { environment } from 'src/environments/environment';
import { AuthService } from '../../domains/auth/auth.service';
import { Organization } from './organization.models';
import { LoggerService } from 'src/app/core/logger/logger.service';


const dummyOrg: Organization = {
  id: '0',
  name: '',
  country: '',
  language: '',
  status: 'active',
  users: [],
  created_at: '',
  updated_at: ''
}

@Injectable({
  providedIn: 'root'
})
export class OrganizationService {
  private organizationData$: BehaviorSubject<Organization> = new BehaviorSubject(dummyOrg);

  constructor(
    private logger: LoggerService,
    private http: HttpClient,
    private authService: AuthService
  ) {
    this.authService.meta$.pipe(
      switchMap(m => {
        if (m.exp === 0) return of(dummyOrg);
        else return this.getOrganizationData(m.organization_id)
      }),
      catchError(() => {
        return of(dummyOrg);
      }),
      tap((org: Organization) => {
        this.organizationData$.next(org)
      })
    ).subscribe({
      next: (org: Organization) => this.logger.info(org, 'OrganizationService'),
      error: error => { this.logger.error(error, 'OrganizationService') }
    });
  }

  getOrganizationData(id: string): Observable<Organization> {
    return this.http.get<Organization>(`${environment.apiEndpoint}/v1/organizations/${id}`)
  }

  public get organization$(): Observable<Organization> {
    return this.organizationData$.asObservable()
  }
}
