import { HttpClient, HttpResponseBase } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable, tap, BehaviorSubject, of, delay, map } from 'rxjs';

import { LoginResponse, TOKEN_KEY, TokenMetadata, UserMetadata } from './auth.models';
import { ActivatedRoute, Router } from '@angular/router';


const dummyMeta: TokenMetadata = {
  exp: 0,
  iat: 0,
  jti: '',
  organization_id: '',
  roles: [],
  user_full_name: '',
  user_id: '',
  username: ''
};

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private token: string = '';
  private meta: TokenMetadata = dummyMeta;
  private _meta$: BehaviorSubject<TokenMetadata> = new BehaviorSubject(dummyMeta);


  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router
  ) {
    const storage = localStorage.getItem(TOKEN_KEY);
    if (storage) {
      this.token = storage;
      const meta = this.jwtDecode(storage);
      if (this.isTokenExpired()) {
        this.token = '';
        this.setMeta(dummyMeta);
      } else {
        this.setMeta(meta);
      }
    }
  }

  private setMeta(meta: TokenMetadata) {
    this.meta = meta;
    this._meta$.next(this.meta);
  }

  private jwtDecode(jwt: string): TokenMetadata {
    const tokens = jwt.split(".");
    if (tokens.length > 1)
      return JSON.parse(atob(tokens[1]));
    else return dummyMeta;
  }


  get userData$() {
    return this._meta$
      .pipe(
        map((data: TokenMetadata) => ({
          roles: data.roles,
          user_full_name: data.user_full_name,
          user_id: data.user_id,
          username: data.username
        }))
      );
  }

  get meta$() {
    return this._meta$.asObservable();
  }

  getToken(): string {
    return this.token;
  }

  login(username: string, password: string): Observable<LoginResponse> {
    return this.http.post<LoginResponse>(
      `${environment.apiEndpoint}/v1/auth/token`,
      {
        grant_type: 'token',
        username,
        password
      }
    ).pipe(
      tap(
        (response: LoginResponse) => {
          this.setToken(response.access_token);
        }
      )
    );
  }

  logout() {
    return this.http.post(`${environment.apiEndpoint}/v1/auth/logout`, null, { observe: 'response' })
      .pipe(
        tap(
          (response: HttpResponseBase) => {
            if (response.status === 200) {
              localStorage.removeItem(TOKEN_KEY);
              this.token = '';
              this.setMeta(dummyMeta);
            }
          }
        )
      );
  }

  requestOneTimePassword(email: string): Observable<void> {
    return this.http.post<void>(`${environment.apiEndpoint}/v1/auth/otp`, { email, delivery_type: 'email' });
  }


  resetPassword(email: string, password: string, otp: string): Observable<void> {
    return this.http.put<void>(`${environment.apiEndpoint}/v1/auth/credentials`, { email, password, otp });
  }

  public checkAndRemoveQueryParameter(parameter: string, shouldRemove: boolean = true): string {
    let params = { ...this.route.snapshot.queryParams };

    if (shouldRemove) {
      this.router.navigate([], {
        queryParams: {
          ...params,
          [parameter]: null
        },
        queryParamsHandling: 'merge'
      });
    }
    return params[parameter];
  }

  public setToken(token: string): void {
    this.token = token;
    if (this.jwtDecode(token) !== undefined) {
      this.setMeta(
        this.jwtDecode(token)
      );
    };
    localStorage.setItem(TOKEN_KEY, token);
  }

  public isAuthenticated(): boolean {
    const local = this.token;
    const storageToken = localStorage.getItem(TOKEN_KEY);
    if (local !== '' || storageToken !== null) {
      return !this.isTokenExpired();
    } else {
      return false;
    }
  }

  public isTokenExpired(token?: string) {
    const data = this.jwtDecode(
      token ? token : this.token
    );
    const now = new Date().getTime();
    return data !== undefined
      ? data.exp * 1e3 <= now
      : true;
  }

}
