import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  BehaviorSubject,
  Observable,
  of,
  tap,
  switchMap,
  catchError,
  finalize
} from 'rxjs';

import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';
import {
  EmbeddedAppConfiguration,
  Organization,
  OrgEntity,
  UpdateOrganization
} from './organization.models';
import { LoggerService } from 'src/app/core/logger/logger.service';

const dummyOrg: Organization = {
  id: '0',
  name: '',
  country: '',
  language: '',
  status: 'active',
  users: [],
  created_at: '',
  updated_at: ''
};

@Injectable({
  providedIn: 'root'
})
export class OrganizationService {
  private organizationData$: BehaviorSubject<Organization> =
    new BehaviorSubject(dummyOrg);
  private loadingOrganisationSubject$: BehaviorSubject<boolean> =
    new BehaviorSubject(false);
  public loadingOrganisationAgent$: Observable<boolean> =
    this.loadingOrganisationSubject$.asObservable();
  constructor(
    private logger: LoggerService,
    private http: HttpClient,
    private authService: AuthService
  ) {
    this.authService.meta$
      .pipe(
        switchMap((m) => {
          if (m.exp === 0) return of(dummyOrg);
          else return this.getOrganizationData(m.organization_id);
        }),
        catchError(() => {
          return of(dummyOrg);
        }),
        tap((org: Organization) => {
          this.organizationData$.next(org);
        })
      )
      .subscribe({
        next: (org: Organization) =>
          this.logger.info(org, 'OrganizationService'),
        error: (error) => {
          this.logger.error(error, 'OrganizationService');
        }
      });
  }

  public getOrganizationData(id: string): Observable<Organization> {
    return this.http.get<Organization>(
      `${environment.apiEndpoint}/v1/organizations/${id}`
    );
  }

  public getOrgEntity(): Observable<OrgEntity> {
    this.loadingOrganisationSubject$.next(true);
    return this.http
      .get<OrgEntity>(`${environment.apiEndpoint}/v1/ai-agents/organizations`)
      .pipe(finalize(() => this.loadingOrganisationSubject$.next(false)));
  }

  public updateOrganization(id: string, data: UpdateOrganization) {
    return this.http.put<UpdateOrganization>(
      `${environment.apiEndpoint}/v1/organizations`,
      data
    );
  }

  public get organization$(): Observable<Organization> {
    return this.organizationData$.asObservable();
  }

  public getEmbeddedApps(): Observable<EmbeddedAppConfiguration> {
    return this.http.get<EmbeddedAppConfiguration>(
      `${environment.apiEndpoint}/v1/embedded-apps`
    );
  }
}
