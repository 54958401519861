import { Routes } from '@angular/router';

export const publicAgentsRoutes: Routes = [
  {
    path: '',
    loadComponent: () =>
      import('../../pages/agents/agents-list/agents-list.component').then((m) => m.AgentsListComponent),
  },
  {
    path: ':id',
    loadComponent: () => import('../../pages/agent-detail/agent-detail.component').then((m) => m.AgentDetailComponent),
    children: [
      {
        path: 'overview',
        loadComponent: () =>
          import('../../pages/agent-detail/overview/overview.component').then((m) => m.OverviewComponent),
      },
      {
        path: 'configure',
        loadComponent: () =>
          import('../../pages/agent-detail/configure/configure.component').then((m) => m.ConfigureComponent),
      },
      {
        path: 'calls',
        loadComponent: () => import('../../pages/agent-detail/calls/calls.component').then((m) => m.CallsComponent),
      },
      {
        path: 'channel',
        loadComponent: () =>
          import('../../pages/agent-detail/channel/channel.component').then((m) => m.ChannelComponent),
      },
      {
        path: '**',
        redirectTo: 'overview',
        pathMatch: 'full',
      },
    ],
  },
];
