export enum Sections {
  Inbox = 'inbox',
  Robocall = 'robocall',
}

export enum ExternalPbxProvider {
  VOXLOUD = 'VOXLOUD'
}

export enum OrganizationStatus {
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
  DISABLED = 'DISABLED'
}

export enum OrganizationType {
  BASIC = 'BASIC',
  PBX_INTEGRATED = 'PBX_INTEGRATED'
}

export enum OrganizationBalanceType {
  TIME = "TIME",
  MONETARY = "MONETARY"
}

export interface ExternalPbx {
  id: string;
  label: string;
  inbound_agent_id: string;
  outbound_agent_id: string;
  created_at: string; // Date in ISO 8601 format
  updated_at: string; // Date in ISO 8601 format
  origination_uri: string;
  termination_uri: string;
  provider: ExternalPbxProvider;
}
export interface Organization {
  id: string;
  name: string;
  country: string;
  language: string;
  status: 'active' | 'inactive';
  users: OrganizationUser[];
  created_at: string;
  updated_at: string;
}

export interface OrganizationUser {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  language: string;
  status: string;
  created_at: string;
  updated_at: string;
}

export interface UpdateOrganization {
  name: string;
  country: string;
  language: string;
}
export interface EmbeddedAppConfiguration {
  organization_id: string;
  sections: Sections[];
}
export interface OrgEntity {
  id: string;
  aggregate_id: string;
  external_pbx: ExternalPbx;
  status: OrganizationStatus;
  type: OrganizationType;
  created_at: string; // Date in ISO 8601 format
  updated_at: string; // Date in ISO 8601 format
  telephony: {
    balance: OrgEntityBalance;
  };
}

export interface OrgEntityBalance {  
  value : string;
  strategy : OrganizationBalanceType;
  is_low : boolean;
}