import { Injectable, Provider } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateParser, TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';

@Injectable()
class RmsPaginatorIntl extends MatPaginatorIntl {
  public readonly changes = new Subject<void>();

  constructor(
    private readonly translateService: TranslateService,
    private readonly translateParser: TranslateParser
  ) {
    super();

    // Initialize the translations once at construction time
    this.getTranslations();

    this.translateService.onLangChange.subscribe(() => {
      this.getTranslations();
    });
  }

  public getRangeLabel = (page: number, pageSize: number, length: number): string => {
    const of: string = this.translateService ? this.translateService.instant('paginator.of') : 'of';

    if (length === 0 || pageSize === 0) {
      return '0 ' + of + ' ' + length;
    }
    length = Math.max(length, 0);

    const startIndex = page * pageSize > length ? (Math.ceil(length / pageSize) - 1) * pageSize : page * pageSize;

    const endIndex = Math.min(startIndex + pageSize, length);

    return startIndex + 1 + ' - ' + endIndex + ' ' + of + ' ' + length;
  };

  private getTranslations(): void {
    this.firstPageLabel = this.translateService.instant('paginator.first-page');
    this.itemsPerPageLabel = this.translateService.instant('paginator.items-per-page');
    this.lastPageLabel = this.translateService.instant('paginator.last-page');
    this.nextPageLabel = this.translateService.instant('paginator.next-page');
    this.previousPageLabel = this.translateService.instant('paginator.previous-page');
    this.changes.next(); // Fire a change event to make sure that the labels are refreshed
  }
}

export function provideRmsPaginatorIntl(): Provider[] {
  return [{ provide: MatPaginatorIntl, useClass: RmsPaginatorIntl }];
}
