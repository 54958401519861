import {
  ENVIRONMENT_INITIALIZER,
  EnvironmentProviders,
  inject,
  Provider
} from '@angular/core';
import { OrganizationService } from './organization.service';
import { AuthService } from '../auth/auth.service';

export function provideOrganization(): Array<Provider | EnvironmentProviders> {
  return [
    {
      provide: ENVIRONMENT_INITIALIZER,
      useValue: () => inject(OrganizationService),
      deps: [AuthService],
      multi: true
    }
  ];
}
