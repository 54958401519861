import { HttpClient } from '@angular/common/http';
import { inject } from '@angular/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { Observable, forkJoin, map } from 'rxjs';


class ModuleTranslationLoader extends TranslateHttpLoader {
  getTranslation(lang: string): Observable<any> {
    const http = inject(HttpClient);
    const modules: string[] = [
      'auth',
      'layout',
      'settings',
      'subscription',
      'integrations'
    ];

    return forkJoin(
      modules.map(
        (name: string) => http.get(`./assets/i18n/${name}/${name}-${lang}.json`)
      )
    ).pipe(
      map((translations) => {
        return Object.assign({}, ...translations);
      })
    );
  }
}


export function customLoaderFactory(http: HttpClient) {
  return new ModuleTranslationLoader(http);
}
