import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'userInitials',
  standalone: true
})
export class UserInitialsPipe implements PipeTransform {

  transform(value: string | null, ...args: unknown[]): string | null {

    if (value === null) {
      return value;
    } else {
      const [first, last] = value.split(' ');
      return `${first ? first[0] : ''}${last ? last[0] : ''}`;
    }
  }

}
