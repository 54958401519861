<rms-base-layout *ngIf="config$ | async as config">
  <vex-progress-bar></vex-progress-bar>

  <rms-search></rms-search>

  <mat-sidenav-container class="vex-layout-sidenav-container">
    <mat-sidenav
      (closedStart)="onSidenavClosed()"
      [disableClose]="!!(sidenavDisableClose$ | async)"
      [fixedInViewport]="!!(sidenavFixedInViewport$ | async)"
      [mode]="(sidenavMode$ | async) ?? 'side'"
      [opened]="!!(sidenavOpen$ | async)">
      <rms-sidenav [collapsed]="!!(sidenavCollapsed$ | async)"></rms-sidenav>
    </mat-sidenav>

    <mat-sidenav
      (closedStart)="onQuickpanelClosed()"
      [opened]="!!(quickpanelOpen$ | async)"
      class="vex-layout-quickpanel"
      mode="over"
      position="end">
      <rms-quickpanel></rms-quickpanel>
    </mat-sidenav>

    <mat-sidenav-content class="vex-layout-sidenav-content">
      <rms-toolbar
        *ngIf="sidenavFixedInViewport$ | async"
        [class.dark]="config.layout === 'vertical'"
        class="vex-toolbar"></rms-toolbar>

      <main class="vex-layout-content">
        <router-outlet></router-outlet>
      </main>

      <rms-footer *ngIf="config.footer.visible" class="vex-footer"></rms-footer>
    </mat-sidenav-content>
  </mat-sidenav-container>

  <!--TODO we will add it when we implement it's logic
  <rms-config-panel-toggle
    (openConfig)="configpanel.open()"></rms-config-panel-toggle>-->

  <!-- CONFIGPANEL -->
  <vex-sidebar
    #configpanel
    [invisibleBackdrop]="true"
    [opened]="!!(configPanelOpen$ | async)"
    position="right">
    <rms-config-panel></rms-config-panel>
  </vex-sidebar>
  <!-- END CONFIGPANEL -->
</rms-base-layout>
