import { ENVIRONMENT_INITIALIZER, EnvironmentProviders, inject, Provider } from '@angular/core';

import { SubscriptionService } from './subscription.service';

export function provideSubscription(): (Provider | EnvironmentProviders)[] {
  return [
    {
      provide: ENVIRONMENT_INITIALIZER,
      useValue: () => inject(SubscriptionService),
      multi: true,
    },
  ];
}
