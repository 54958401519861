import { MatSnackBarConfig } from '@angular/material/snack-bar';

export const DEFAULT_TIMEOUT = 3000;
export const DEFAULT_SNACKBAR_CONFIG: MatSnackBarConfig = {
  horizontalPosition: 'center',
  verticalPosition: 'top',
  duration: DEFAULT_TIMEOUT,
  panelClass: ['rounded', 'p-2', 'bg-viridis'],
};

export const WARNING_SNACKBAR_CONFIG: MatSnackBarConfig = {
  horizontalPosition: 'center',
  verticalPosition: 'top',
  duration: DEFAULT_TIMEOUT,
  panelClass: ['rounded', 'p-2', 'bg-ochraceus'],
};

export const ERROR_SNACKBAR_CONFIG: MatSnackBarConfig = {
  horizontalPosition: 'center',
  verticalPosition: 'top',
  duration: DEFAULT_TIMEOUT,
  panelClass: ['rounded', 'p-2', 'bg-ruber', 'text-flavius'],
};

export const MAX_NOTIFICATIONS = 5;

export interface NotificationMetadata {
  icon?: string;
  color?: string;
  title?: string;
  message?: string;
  canClose?: boolean;
}
