<div class="vex-user-menu">
  <!-- TODO: uncomment if needed -->
  <!-- <a
       (click)="close()"
       [routerLink]="['/coming-soon']"
       class="vex-user-menu-item"
       matRipple
       matRippleColor="rgb(var(--vex-color-primary-600), 0.1)">
       <mat-icon
       class="vex-user-menu-item__icon icon-sm"
       svgIcon="mat:account_circle"></mat-icon>
       <div class="vex-user-menu-item__label" translate>Coming soon</div>
       </a>
       <a
       (click)="close()"
       [routerLink]="['/profile']"
       class="vex-user-menu-item"
       matRipple
       matRippleColor="rgb(var(--vex-color-primary-600), 0.1)">
       <mat-icon
       class="vex-user-menu-item__icon icon-sm"
       svgIcon="mat:account_circle"></mat-icon>
       <div class="vex-user-menu-item__label" translate>user-menu.profile</div>
       </a>

       <a
       (click)="close()"
       [routerLink]="['/preferences']"
       class="vex-user-menu-item"
       matRipple
       matRippleColor="rgb(var(--vex-color-primary-600), 0.1)">
       <mat-icon
       class="vex-user-menu-item__icon icon-sm"
       svgIcon="mat:settings"></mat-icon>
       <div class="vex-user-menu-item__label" translate>user-menu.account-settings</div>
       </a>
       <a
       (click)="close()"
       [routerLink]="['/subscription']"
       class="vex-user-menu-item"
       matRipple
       matRippleColor="rgb(var(--vex-color-primary-600), 0.1)">
       <mat-icon
       class="vex-user-menu-item__icon icon-sm"
       svgIcon="remix:Finance/bank-card-line"></mat-icon>
       <div class="vex-user-menu-item__label" translate>user-menu.subscription</div>
       </a>

       <div class="border-b border-divider mx-4"></div> -->

  <a
    (click)="close()"
    [routerLink]="['/login']"
    class="vex-user-menu-item"
    matRipple
    matRippleColor="rgb(var(--vex-color-primary-600), 0.1)">
    <mat-icon
      class="vex-user-menu-item__icon icon-sm"
      svgIcon="remix:System/logout-box-line"></mat-icon>
    <div class="vex-user-menu-item__label" translate>user-menu.logout</div>
  </a>
</div>
