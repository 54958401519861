import { Injectable } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { SupportedLanguage } from 'src/environments/environment.model';
import { LANGUAGE_TOKEN } from './language-storage-token.const';
import { DEFAULT_LANGUAGE } from './default-language.const';

@Injectable({
  providedIn: 'root'
})
export class LanguageDetectionService {

  constructor(private translateService: TranslateService) {
    this.detectLanguage();
    this.translateService.onLangChange.subscribe((e: LangChangeEvent) => {
      const match = this.findSupported(e.lang);
      this.storeLanguage(match.locale);
    })
  }

  private detectLanguage() {
    const storageLanguage = localStorage.getItem(LANGUAGE_TOKEN);
    const browserLanguage: SupportedLanguage = this.findSupported(
      this.translateService.getBrowserLang()
    );

    if (storageLanguage) {
      this.setTranslateLanguage(
        this.findSupported(storageLanguage)
      )
    } else {
      this.setTranslateLanguage(browserLanguage);
    }

  }

  private findSupported(lang: string | undefined): SupportedLanguage {
    return environment
      .supportedLanguages
      .find((l: SupportedLanguage) => l.locale === lang) || DEFAULT_LANGUAGE;
  }

  private setTranslateLanguage(lang: SupportedLanguage) {
    if (lang.locale !== this.translateService.currentLang) {
      this.translateService.setDefaultLang(lang.locale);
      this.translateService.use(lang.locale);
    }
  }

  storeLanguage(lang: string) {
    localStorage.setItem(LANGUAGE_TOKEN, lang);
  }

}
