<div
  class="balance {{ isLow || error ? 'text-ruber' : '' }}"
  [matTooltip]="'common.balance-counter.low-balance' | translate"
  [matTooltipDisabled]="!isLow">
  @if (loading) {
    <ngx-skeleton-loader [theme]="{ 'width.px': 200, 'height.px': 35, 'margin-bottom.px': 0 }"></ngx-skeleton-loader>
  } @else {
    @if (balance) {
      <mat-icon
        class="balance-icon"
        [svgIcon]="icon"></mat-icon>
      <span [innerHTML]="label | translate: { value: balance.value }"></span>
    }
    @if (error) {
      <div class="mr-2">
        <button
          class="square-button compact-button button-sm"
          color="secondary"
          (click)="fetchData()"
          aria-label="Error"
          mat-stroked-button>
          <mat-icon
            class="icon-xs"
            svgIcon="remix:System/refresh-line"></mat-icon>
        </button>
      </div>
      <span class="text-ruber">{{ label | translate }}</span>
    }
  }
</div>
