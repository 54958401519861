import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar'
import { TranslateService } from '@ngx-translate/core';

import { DEFAULT_SNACKBAR_CONFIG, ERROR_SNACKBAR_CONFIG } from './notification.models';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from 'src/app/domains/shared/components/confirmation-dialog/confirmation-dialog.component';
import { DIALOG_CONFIG } from '../config/mat-dialog.config';
import { take } from 'rxjs';
import { CONFIRM_RESULT } from 'src/app/domains/shared/models/confirmation.model';

/**
 * Wrapper around MatSnackBar
 */

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  constructor(
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private translateService: TranslateService
  ) { }

  public notify(message: string, action: string, config?: MatSnackBarConfig): void {
    this.snackBar.open(
      this.translateService.instant(message),
      this.translateService.instant(action),
      config ? config : DEFAULT_SNACKBAR_CONFIG
    )
  }

  public error(message: string, action: string, config?: MatSnackBarConfig): void {
    this.snackBar.open(
      this.translateService.instant(message),
      this.translateService.instant(action),
      config ? config : ERROR_SNACKBAR_CONFIG
    )
  }

  public confirm(title: string, message: string, callback: Function): MatDialogRef<ConfirmationDialogComponent> {
    const ref = this.dialog.open(
      ConfirmationDialogComponent,
      {
        ...DIALOG_CONFIG,
        minHeight: '10vh',
        minWidth: '320px',
        data: { message: this.translateService.instant(message) }
      });
    ref.componentInstance.result.pipe(
      take(1)
    ).subscribe(
      (result: CONFIRM_RESULT) => {
        callback(result);
        ref.close(result);
      }
    );
    ref.componentInstance.title = title;
    ref.componentInstance.message = message;
    return ref;
  }

}
