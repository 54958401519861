import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { filter, take } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SupportedLanguage } from 'src/environments/environment.model';

import { LanguageDetectionService } from './language-detection.service';

@Injectable({
  providedIn: 'root',
})
export class LanguageParamResolver implements Resolve<void> {
  constructor(
    private translateService: TranslateService,
    private languageDetectionService: LanguageDetectionService
  ) {}

  public resolve(route: ActivatedRouteSnapshot): void {
    const lang = route.queryParamMap.get('lang');
    const isSupported = environment.supportedLanguages.some((l: SupportedLanguage) => l.locale === lang);
    if (lang && isSupported) {
      // wait until language detection is completed
      this.languageDetectionService.languageDetectionComplete
        .pipe(
          filter((e) => e === true),
          take(1)
        )
        .subscribe(() => {
          this.translateService.use(lang);
        });
    }
  }
}
