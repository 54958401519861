import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateLoader } from '@ngx-translate/core';
import { Observable, forkJoin, map } from 'rxjs';

@Injectable()
export class ModuleTranslationLoader extends TranslateLoader {
  constructor(private http: HttpClient) {
    super();
  }

  getTranslation(lang: string): Observable<any> {
    const modules: string[] = [
      'inbox',
      'agent',
      'auth',
      'common',
      'layout',
      'settings',
      'subscription',
      'integrations'
    ];

    return forkJoin(
      modules.map((name: string) =>
        this.http.get(`./assets/i18n/${name}/${name}-${lang}.json`)
      )
    ).pipe(
      map((translations) => {
        return Object.assign({}, ...translations);
      })
    );
  }
}


export function customLoaderFactory(http: HttpClient) {
  return new ModuleTranslationLoader(http);
}
