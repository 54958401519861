import { Component } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { RouterLink } from '@angular/router';
import { DateTime } from 'luxon';

@Component({
  selector: 'rms-quickpanel',
  templateUrl: './quickpanel.component.html',
  styleUrls: ['./quickpanel.component.scss'],
  standalone: true,
  imports: [MatDividerModule, MatListModule, RouterLink, MatRippleModule, MatProgressBarModule],
})
export class QuickpanelComponent {
  date = DateTime.local().toFormat('DD');
  dayName = DateTime.local().toFormat('EEEE');
}
