import { AppComponent } from './app/app.component';
import { bootstrapApplication } from '@angular/platform-browser';
import { appConfig } from './app/app.config';
import localeIt from '@angular/common/locales/it';
import localeItExtra from '@angular/common/locales/extra/it';
import { registerLocaleData } from '@angular/common';
bootstrapApplication(AppComponent, appConfig).catch((err) =>
  console.error(`[ERROR] [BOOTSTRAP] ${new Date().toISOString()}`, err)
);
registerLocaleData(localeIt, 'it', localeItExtra);
