import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'userInitials',
  standalone: true
})
export class UserInitialsPipe implements PipeTransform {

  transform(value: string | null): string | null {

    if (value === null) {
      return value;
    } else {
      const [first, last] = value
        .trim()
        .split(/\s+/)
        // Below i use Unicode property escape for letters from different alphabets, e.g. cyrillic
        .map((name: string) => name.replace(/[^\p{L}]/gu, ''))
        .filter((name: string) => name.length > 0)

      return `${first ? first[0] : ''}${last ? last[0] : ''}`;
    }
  }

}
