import { KeyValue } from '@angular/common';

export function trackByRoute<T extends { route: string | string[] }>(_: number, item: T) {
  return item.route;
}

export function trackById<T extends { id: string | number }>(_: number, item: T) {
  return item.id;
}

export function trackByKey(_: number, item: KeyValue<any, any>) {
  return item.key;
}

export function trackByValue(_: number, value: string) {
  return value;
}

export function trackByLabel<T extends { label: string }>(_: number, value: T) {
  return value.label;
}
