import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs';
import { ConfirmationDialogComponent } from 'src/app/domains/shared/components/confirmation-dialog';
import { CONFIRM_RESULT } from 'src/app/domains/shared/models/confirmation.model';

import { DIALOG_CONFIG } from '../config/mat-dialog.config';
import { DEFAULT_SNACKBAR_CONFIG, ERROR_SNACKBAR_CONFIG } from './notification.models';

/**
 * Wrapper around MatSnackBar
 */

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private translateService: TranslateService
  ) {}

  public notify(messageToken: string, actionToken: string, config?: MatSnackBarConfig, onAction?: () => void): void {
    this.translateService.get([messageToken, actionToken]).subscribe((data: any) => {
      const snackBarRef = this.snackBar.open(
        data[messageToken],
        data[actionToken],
        config ? config : DEFAULT_SNACKBAR_CONFIG
      );
      if (onAction) {
        snackBarRef.onAction().subscribe(() => onAction());
      }
    });
  }

  public error(messageToken: string, actionToken: string, config?: MatSnackBarConfig): void {
    this.translateService.get([messageToken, actionToken]).subscribe((data: any) => {
      this.snackBar.open(data[messageToken], data[actionToken], config ? config : ERROR_SNACKBAR_CONFIG);
    });
  }

  public confirm(
    title: string,
    message: string,
    callback: (result: CONFIRM_RESULT) => void
  ): MatDialogRef<ConfirmationDialogComponent> {
    const ref = this.dialog.open(ConfirmationDialogComponent, {
      ...DIALOG_CONFIG,
      minHeight: '10vh',
      minWidth: '320px',
      data: { message: this.translateService.instant(message) },
    });
    ref.componentInstance.result.pipe(take(1)).subscribe((result: CONFIRM_RESULT) => {
      callback(result);
      ref.close(result);
    });
    ref.componentInstance.title = title;
    ref.componentInstance.message = message;

    return ref;
  }
}
