import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { ActivatedRoute } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { VexPopoverService } from '@vex/components/vex-popover/vex-popover.service';
import { VexScrollbarComponent } from '@vex/components/vex-scrollbar/vex-scrollbar.component';
import { VexConfigService } from '@vex/config/vex-config.service';
import { VexLayoutService } from '@vex/services/vex-layout.service';
import { Observable, of } from 'rxjs';
import { map, startWith, switchMap } from 'rxjs/operators';

import { NavigationService } from '../../../core/navigation/navigation.service';
import { NavigationItem } from '../../../core/navigation/navigation-item.interface';
import { TokenMetadata } from '../../../domains/auth/auth.models';
import { AuthService } from '../../../domains/auth/auth.service';
import { Organization } from '../../../domains/org/organization.models';
import { OrganizationService } from '../../../domains/org/organization.service';
import { UserInitialsPipe } from '../../../domains/shared/pipes/user-initials.pipe';
import { BalanceCounterComponent } from '../balance-counter/balance-counter.component';
import { SearchModalComponent } from './search-modal/search-modal.component';
import { SidenavItemComponent } from './sidenav-item/sidenav-item.component';
import { SidenavUserMenuComponent } from './sidenav-user-menu/sidenav-user-menu.component';

@Component({
  selector: 'rms-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    MatButtonModule,
    MatIconModule,
    MatRippleModule,
    VexScrollbarComponent,
    NgFor,
    SidenavItemComponent,
    BalanceCounterComponent,
    AsyncPipe,
    TranslateModule,
    UserInitialsPipe,
  ],
})
export class SidenavComponent {
  @Input() collapsed = false;
  collapsedOpen$ = this.layoutService.sidenavCollapsedOpen$;
  title$ = this.configService.config$.pipe(map((config) => config.sidenav.title));
  imageUrl$ = this.configService.config$.pipe(map((config) => config.sidenav.imageUrl));
  collapseImageUrl$ = this.configService.config$.pipe(map((config) => config.sidenav.collapseImageUrl));
  showCollapsePin$ = this.configService.config$.pipe(map((config) => config.sidenav.showCollapsePin));
  userVisible$ = this.configService.config$.pipe(map((config) => config.sidenav.user.visible));
  searchVisible$ = this.configService.config$.pipe(map((config) => config.sidenav.search.visible));
  userName$ = this.authService.meta$.pipe(map((meta: TokenMetadata) => meta.user_full_name));

  org$ = this.organizationService.organization$.pipe(map((org: Organization) => org.name));

  userMenuOpen$: Observable<boolean> = of(false);

  items$: Observable<NavigationItem[]> = this.navigationService.items$.pipe(
    map((navigationItems) =>
      navigationItems.filter(
        (item) =>
          item.type !== 'link' ||
          !item.restricted ||
          !item.section ||
          this.route.snapshot.data['embeddedApps'].sections.includes(item.section)
      )
    )
  );

  constructor(
    private navigationService: NavigationService,
    private layoutService: VexLayoutService,
    private configService: VexConfigService,
    private authService: AuthService,
    private organizationService: OrganizationService,
    private readonly popoverService: VexPopoverService,
    private readonly dialog: MatDialog,
    private readonly route: ActivatedRoute
  ) {}

  collapseOpenSidenav() {
    this.layoutService.collapseOpenSidenav();
  }

  collapseCloseSidenav() {
    this.layoutService.collapseCloseSidenav();
  }

  toggleCollapse() {
    this.collapsed ? this.layoutService.expandSidenav() : this.layoutService.collapseSidenav();
  }

  trackByRoute(_: number, item: NavigationItem): string {
    if (item.type === 'link') {
      return item.route;
    }

    return item.label;
  }

  openProfileMenu(origin: HTMLDivElement): void {
    this.userMenuOpen$ = of(
      this.popoverService.open({
        content: SidenavUserMenuComponent,
        origin,
        offsetY: -8,
        width: origin.clientWidth,
        position: [
          {
            originX: 'center',
            originY: 'top',
            overlayX: 'center',
            overlayY: 'bottom',
          },
        ],
      })
    ).pipe(
      switchMap((popoverRef) => popoverRef.afterClosed$.pipe(map(() => false))),
      startWith(true)
    );
  }

  openSearch(): void {
    this.dialog.open(SearchModalComponent, {
      panelClass: 'vex-dialog-glossy',
      width: '100%',
      maxWidth: '600px',
    });
  }
}
