import { ENVIRONMENT_INITIALIZER, EnvironmentProviders, inject, Provider } from '@angular/core';

import { AuthService } from '../auth/auth.service';
import { OrganizationService } from './organization.service';

export function provideOrganization(): (Provider | EnvironmentProviders)[] {
  return [
    {
      provide: ENVIRONMENT_INITIALIZER,
      useValue: () => inject(OrganizationService),
      deps: [AuthService],
      multi: true,
    },
  ];
}
