import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { AuthService } from 'src/app/domains/auth/auth.service';

import { EXCLUDED_URL_PATTERNS } from '../excluded-domains.const';

export const tokenInterceptor: HttpInterceptorFn = (req, next) => {
  const authService = inject(AuthService);
  const shouldSkip = (url: string): boolean => {
    return EXCLUDED_URL_PATTERNS.some((pattern: RegExp) => pattern.test(url));
  };

  if (shouldSkip(req.url)) {
    return next(req);
  } else {
    const request = req.clone({
      setHeaders: {
        authorization: `Bearer ${authService.getToken()}`,
      },
    });

    return next(request);
  }
};
