import { AsyncPipe, NgFor } from '@angular/common';
import { Component } from '@angular/core';
import { Observable } from 'rxjs';

import { NavigationService } from '../../../core/navigation/navigation.service';
import { NavigationItem } from '../../../core/navigation/navigation-item.interface';
import { NavigationItemComponent } from './navigation-item/navigation-item.component';

@Component({
  selector: 'rms-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
  standalone: true,
  imports: [NgFor, NavigationItemComponent, AsyncPipe],
})
export class NavigationComponent {
  items$: Observable<NavigationItem[]> = this.navigationService.items$;

  constructor(private navigationService: NavigationService) {}
}
