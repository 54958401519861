import { ResolveFn } from '@angular/router';
import { inject } from '@angular/core';
import { map, Observable, tap } from 'rxjs';

import { OrganizationService } from './organization.service';
import { EmbeddedAppConfiguration, Sections } from './organization.models';
import { InboxService } from '../inbox/inbox.service';

export const embeddedAppsResolver: ResolveFn<
  Observable<EmbeddedAppConfiguration>
> = (route, state) => {
  const organizationService = inject(OrganizationService);
  const inboxService = inject(InboxService);

  return organizationService.getEmbeddedApps().pipe(
    /*
     * to get inbox iframe ASAP
     * */
    tap(({ sections }) => {
      if (inboxService.checkSectionPresence(sections, Sections.Inbox)) {
        inboxService.init();
      }
    })
  );
};
