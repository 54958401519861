import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { VexLayoutService } from '@vex/services/vex-layout.service';
import { BehaviorSubject, combineLatest, filter, Observable, Subject, Subscription, switchMap, tap } from 'rxjs';
import { NotificationService } from 'src/app/core/notification/notification.service';
import { AuthService } from 'src/app/domains/auth/auth.service';
import { environment } from 'src/environments/environment';

import { InboxPostMessage, InboxTokenResponse } from './inbox.models';

@Injectable({
  providedIn: 'root',
})
export class InboxService implements OnDestroy {
  private _iframe: HTMLIFrameElement = document.createElement('iframe');

  private notifications: InboxPostMessage[] = [];
  private _notificationsCount = 0;
  private sidenavSubscription: Subscription | undefined = undefined;

  public notifications$ = new BehaviorSubject<InboxPostMessage[]>(this.notifications);
  public notificationsCount$ = new BehaviorSubject<number>(this._notificationsCount);

  public notificationAdded$ = new Subject<InboxPostMessage>();
  public notificationRemoved$ = new Subject<string>();

  public frameReady$ = new BehaviorSubject<boolean>(false);
  public chatIsReady$ = new BehaviorSubject<boolean>(true);
  public loading$ = new BehaviorSubject<boolean>(false);

  constructor(
    private router: Router,
    private notificationService: NotificationService,
    private http: HttpClient,
    private authService: AuthService,
    private layout: VexLayoutService
  ) {
    this.attachPostMessage();
  }

  ngOnDestroy() {
    this.sidenavSubscription?.unsubscribe();
    this.destroy();
  }

  public init() {
    this.loading$.next(true);

    this.authService.meta$
      .pipe(
        filter((m) => m.exp !== 0),
        switchMap(() => {
          return this.getInboxToken();
        })
      )
      .subscribe({
        next: (data: InboxTokenResponse) => {
          this.initIframe(data.token);
          this.chatIsReady$.next(true);
          this.loading$.next(false);
        },
        error: () => {
          this.frameReady$.next(false);
          this.chatIsReady$.next(false);
          this.loading$.next(false);
        },
      });
  }

  getInboxToken(): Observable<InboxTokenResponse> {
    return this.http.get<InboxTokenResponse>(`${environment.apiEndpoint}/v1/users/token`);
  }

  private initIframe(token: string) {
    this._iframe.src = `https://chat.romulus.live/api-login?token=${token}`;
    this._iframe.className = 'border-t-2 border-b-2 border-l-2 rounded-tl-md rounded-bl-md bg-white';
    this._iframe.id = 'inbox-frame';
    this._iframe.style.position = 'absolute';
    this._iframe.style.display = 'block';
    this._iframe.allow = 'clipboard-write *';
    this.hideIframe();

    document.body.appendChild(this._iframe);
    this.frameReady$.next(true);

    this._iframe.addEventListener('error', () => {
      this.notificationService.error('inbox.errors.frame', 'common.ok');
      this.frameReady$.next(false);
    });

    if (this.sidenavSubscription === undefined) {
      combineLatest([this.layout.sidenavOpen$, this.layout.isDesktop$])
        .pipe(
          tap(([sidenavOpen, isDesktop]: [boolean, boolean]) => {
            setTimeout(() => {
              this._iframe.style.zIndex = sidenavOpen && !isDesktop ? '0' : '1';
            }, 50);
          })
        )
        .subscribe();
    }
  }

  public hideIframe() {
    this._iframe.style.zIndex = '0';
    this._iframe.style.visibility = 'hidden';
    this._iframe.hidden = true;
  }

  public showIframe() {
    this._iframe.style.zIndex = '1';
    this._iframe.style.visibility = 'visible';
    this._iframe.hidden = false;
  }

  public positionFrame(pos: DOMRect) {
    this._iframe.style.top = `${pos.y}px`;
    this._iframe.style.left = `${pos.x}px`;
    this._iframe.style.width = `${pos.width}px`;
    this._iframe.style.height = `${pos.height}px`;
  }

  public destroy(): void {
    this._iframe.remove();
    window.removeEventListener('message', this.postMessageCallback);
  }

  public get iframe(): HTMLIFrameElement {
    return this._iframe;
  }

  public get notificationsCount(): number {
    return this._notificationsCount;
  }

  public removeNotificationAt(index: number) {
    const removed = this.notifications.splice(index, 1);
    this.notifications$.next(this.notifications);
    if (removed[0]?.id) {
      this.notificationRemoved$.next(removed[0].id);
    }
  }

  public removeNotification(notification: InboxPostMessage) {
    this.removeNotificationAt(
      this.notifications.findIndex((n: InboxPostMessage) => {
        return n.id === notification.id;
      })
    );
  }

  private attachPostMessage() {
    window.addEventListener('message', this.postMessageCallback);
  }

  private postMessageCallback = (message: any) => {
    if (message.origin === 'https://chat.romulus.live' && message.data) this.processPostMessage(message.data);
  };

  private processPostMessage(message: InboxPostMessage): void {
    message.id = `message_${Math.random().toString(16).slice(2)}`;
    this.addNotification(message);
    if (this.router.url !== '/inbox') {
      this.addNotificationCount();
    }
  }

  private addNotification(message: InboxPostMessage) {
    this.notifications.push(message);
    this.notifications$.next(this.notifications);
    this.notificationAdded$.next(message);
  }

  private clearAllNotifications(): void {
    this.notifications = [];
    this.notifications$.next(this.notifications);
  }

  private addNotificationCount(): void {
    this._notificationsCount++;
    this.notificationsCount$.next(this._notificationsCount);
  }

  public clearNotificationCount(): void {
    this._notificationsCount = 0;
    this.notificationsCount$.next(this._notificationsCount);
    this.clearAllNotifications();
  }

  checkSectionPresence(sections: string[], sectionToCheck: string): boolean {
    return sections.includes(sectionToCheck);
  }
}
