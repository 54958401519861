import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

import { CONFIRM_RESULT } from '../../models/confirmation.model';
import { CloseButtonComponent } from '../close-button/close-button.component';

@Component({
  selector: 'rms-confirmation-dialog',
  standalone: true,
  imports: [MatButtonModule, MatIconModule, TranslateModule, CloseButtonComponent],
  templateUrl: './confirmation-dialog.component.html',
  styleUrl: './confirmation-dialog.component.scss',
})
export class ConfirmationDialogComponent {
  @Input() title = 'common.confirmation';
  @Input() message = '';
  @Output() result: EventEmitter<CONFIRM_RESULT> = new EventEmitter<CONFIRM_RESULT>(undefined);

  ok() {
    this.result.emit(CONFIRM_RESULT.OK);
  }

  cancel() {
    this.result.emit(CONFIRM_RESULT.CANCEL);
  }

  dismiss() {
    this.result.emit(CONFIRM_RESULT.DISMISS);
  }
}
