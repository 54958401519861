import {
  Provider,
  EnvironmentProviders
} from '@angular/core';
import { LanguageDetectionService } from './language-detection.service';

export function provideLanguageDetection(): Array<Provider | EnvironmentProviders> {
  return [{
    provide: LanguageDetectionService
  }];
}
