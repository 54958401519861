import { HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, throwError } from 'rxjs';

import { ERROR_CODES } from '../error-codes.const';

export const authErrorInterceptor: HttpInterceptorFn = (req, next) => {
  const router = inject(Router);

  return next(req).pipe(
    catchError((error: HttpErrorResponse) => {
      if (
        ERROR_CODES.includes(error.status) &&
        !router.getCurrentNavigation()?.finalUrl?.toString().includes('login')
      ) {
        router.navigate(['/login'], { state: ['auth.expired', 'common.ok'] });
      }

      return throwError(() => error);
    })
  );
};
