import { Injectable } from '@angular/core';
import { NavigationItem } from './navigation-item.interface';
import { BehaviorSubject, Observable } from 'rxjs';
import { Sections } from '../../domains/org/organization.models';

@Injectable({
  providedIn: 'root'
})
export class NavigationLoaderService {
  private readonly _items: BehaviorSubject<NavigationItem[]> =
    new BehaviorSubject<NavigationItem[]>([]);

  get items$(): Observable<NavigationItem[]> {
    return this._items.asObservable();
  }

  constructor() {
    this.loadNavigation();
  }

  loadNavigation(): void {
    this._items.next([
      // TODO: uncomment when ready to work on home section
      // {
      //   type: 'link',
      //   label: 'menu.home',
      //   route: '/home',
      //   restricted: false,
      //   icon: 'remix:Buildings/home-smile-2-line',
      //   hoverIcon: 'remix:Buildings/home-smile-2-fill',
      //   routerLinkActiveOptions: { exact: true }
      // },
      {
        type: 'link',
        label: 'menu.inbox',
        route: '/inbox',
        section: Sections.Inbox,
        restricted: true,
        icon: 'remix:Business/inbox-line',
        hoverIcon: 'remix:Business/inbox-fill',
        routerLinkActiveOptions: { exact: true }
      },
      {
        type: 'link',
        label: 'menu.agents',
        route: '/agents',
        restricted: false,
        icon: 'remix:Logos/bard-line',
        hoverIcon: 'remix:Logos/bard-fill',
        routerLinkActiveOptions: { exact: false }
      },
      {
        type: 'link',
        label: 'menu.integrations',
        route: '/integrations',
        restricted: false,
        icon: 'remix:Development/puzzle-2-line',
        hoverIcon: 'remix:Development/puzzle-2-fill',
        routerLinkActiveOptions: { exact: false }
      },
      {
        type: 'link',
        label: 'menu.settings',
        route: '/settings',
        restricted: false,
        icon: 'remix:System/settings-6-line',
        hoverIcon: 'remix:System/settings-6-fill',
        routerLinkActiveOptions: { exact: false }
      }
    ]);
  }
}
