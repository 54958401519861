import { ENVIRONMENT_INITIALIZER, EnvironmentProviders, inject, Provider } from '@angular/core';

import { AuthService } from '../auth/auth.service';
import { InboxService } from './inbox.service';

export function provideInbox(): (Provider | EnvironmentProviders)[] {
  return [
    {
      provide: ENVIRONMENT_INITIALIZER,
      useValue: () => inject(InboxService),
      deps: [AuthService],
      multi: true,
    },
  ];
}
