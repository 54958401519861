import {
  ApplicationConfig,
  importProvidersFrom,
  LOCALE_ID
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { appRoutes } from './app.routes';
import { provideAnimations } from '@angular/platform-browser/animations';
import {
  HttpClient,
  provideHttpClient,
  withInterceptors
} from '@angular/common/http';
import { provideRouter, withInMemoryScrolling } from '@angular/router';
import { MatDialogModule } from '@angular/material/dialog';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatNativeDateModule } from '@angular/material/core';

import { provideQuillConfig } from 'ngx-quill';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

import { provideVex } from '@vex/vex.provider';
import { vexConfigs } from '@vex/config/vex-configs';

import { provideIcons } from './core/icons/icons.provider';
import { provideLuxon } from './core/luxon/luxon.provider';
import { provideNavigation } from './core/navigation/navigation.provider';
import { provideNotifications } from './core/notification/notification.provider';
import { tokenInterceptor } from './core/http/token-interceptor/token.interceptor';
import { authErrorInterceptor } from './core/http/auth-error-interceptor/auth-error.interceptor';

import { provideSubscription } from './domains/subscription/subscription.provider';
import { provideOrganization } from './domains/org/organization.provider';
import { provideInbox } from './domains/inbox/inbox.provider';
import { customLoaderFactory } from './core/http/translation.loader';
import { provideLanguageDetection } from './core/language-detection/language-detection.provider';
import { environment } from 'src/environments/environment';

export const appConfig: ApplicationConfig = {
  providers: [
    { provide: LOCALE_ID, useValue: 'it' },
    importProvidersFrom(
      BrowserModule,
      MatDialogModule,
      MatBottomSheetModule,
      MatNativeDateModule,
      TranslateModule.forRoot({
        defaultLanguage: environment.defaultLanguage,
        loader: {
          provide: TranslateLoader,
          useFactory: customLoaderFactory,
          deps: [HttpClient]
        }
      })
    ),
    provideLanguageDetection(),
    provideSubscription(),
    provideRouter(
      appRoutes,
      // TODO: Add preloading withPreloading(),
      withInMemoryScrolling({
        anchorScrolling: 'enabled',
        scrollPositionRestoration: 'enabled'
      })
    ),
    provideNotifications(),
    provideAnimations(),
    provideHttpClient(withInterceptors([
      tokenInterceptor,
      authErrorInterceptor
    ])),
    provideOrganization(),
    provideInbox(),
    provideVex({
      /**
       * The config that will be used by default.
       * This can be changed at runtime via the config panel or using the VexConfigService.
       */
      config: vexConfigs.romulus,
      /**
       * Only themes that are available in the config in tailwind.config.ts should be listed here.
       * Any theme not listed here will not be available in the config panel.
       */
      availableThemes: [
        {
          name: 'Default',
          className: 'vex-theme-default'
        },
        {
          name: 'Teal',
          className: 'vex-theme-teal'
        },
        {
          name: 'Green',
          className: 'vex-theme-green'
        },
        {
          name: 'Purple',
          className: 'vex-theme-purple'
        },
        {
          name: 'Red',
          className: 'vex-theme-red'
        },
        {
          name: 'Orange',
          className: 'vex-theme-orange'
        },
        {
          name: 'Ruber',
          className: 'vex-theme-ruber'
        }
      ]
    }),
    provideNavigation(),
    provideIcons(),
    provideLuxon(),
    provideQuillConfig({
      modules: {
        toolbar: [
          ['bold', 'italic', 'underline', 'strike'],
          ['blockquote', 'code-block'],
          [{ list: 'ordered' }, { list: 'bullet' }],
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          ['clean'],
          ['link', 'image']
        ]
      }
    })
  ]
};
