import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { LanguageDetectionService } from './core/language-detection/language-detection.service';

@Component({
  selector: 'rms-root',
  templateUrl: './app.component.html',
  standalone: true,
  imports: [RouterOutlet]
})
export class AppComponent {
  constructor(lang: LanguageDetectionService) { }
}
