import { Component, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { LoggerService } from 'src/app/core/logger/logger.service';
import { OrganizationBalanceType, OrgEntity, OrgEntityBalance } from 'src/app/domains/org/organization.models';
import { OrganizationService } from 'src/app/domains/org/organization.service';

@Component({
  selector: 'rms-balance-counter',
  standalone: true,
  imports: [MatIconModule, NgxSkeletonLoaderModule, MatButtonModule, MatTooltipModule, TranslateModule],
  templateUrl: './balance-counter.component.html',
  styleUrl: './balance-counter.component.scss',
})
export class BalanceCounterComponent implements OnInit {
  public loading = false;
  public balance!: OrgEntityBalance;
  public error = false;
  private readonly LOW_BALANCE_THRESHOLD = 50;

  constructor(
    private organizationService: OrganizationService,
    private logger: LoggerService
  ) {}

  ngOnInit(): void {
    this.fetchData();
  }

  public fetchData() {
    this.error = false;
    this.loading = true;
    this.organizationService.getOrgEntity().subscribe({
      next: (entity: OrgEntity) => {
        this.balance = entity.telephony.balance;
        this.loading = false;
        this.logger.info(entity.telephony, 'BalanceCounterComponent.fetchData');
      },
      error: (err) => {
        this.loading = false;
        this.error = true;
        this.logger.error(err, 'BalanceCounterComponent.fetchData');
      },
    });
  }

  public get isLow(): boolean {
    return this.balance ? Number(this.balance.value) <= this.LOW_BALANCE_THRESHOLD : false;
  }

  public get label(): string {
    return this.error
      ? 'common.balance-counter.error-message'
      : 'common.balance-counter.' + this.balance.strategy.toLowerCase();
  }

  public get icon(): string {
    switch (this.balance.strategy) {
      case OrganizationBalanceType.MONETARY: {
        return 'remix:Finance/coins-line';
      }
      case OrganizationBalanceType.TIME: {
        return 'remix:Device/phone-line';
      }
      default: {
        return this.isLow ? 'remix:System/alarm-warning-fill' : 'remix:System/question-line';
      }
    }
  }
}
