import { Observable, tap } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ChargebeeInstance, Portal } from 'chargebee-js-types';

import { environment } from 'src/environments/environment';
import {
  PORTAL_SECTIONS,
  PortalSession,
  PortalSessionResponse,
  PortalSubscriptionEvent
} from './subscription.model';
import { LoggerService } from 'src/app/core/logger/logger.service';

declare const Chargebee: ChargebeeInstance;

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {
  private instance: ChargebeeInstance;
  private session: PortalSession | undefined = undefined;
  private sections: { [key: string]: string } = PORTAL_SECTIONS;
  private portal: Portal | undefined;
  private refreshTimer: number = -1;

  constructor(
    private http: HttpClient,
    private logger: LoggerService
  ) {
    this.instance = (Chargebee as any).init({
      site: environment.chargebeeSite
    });
  }

  public openPortal(section: string) {
    if (this.session) {
      this.portal = this.instance.createChargebeePortal();
      this.portal.openSection(
        {
          sectionType: this.sections?.[section],
          params: {
            // subscriptionId is required if section involves subscription
            // more here:
            // https://www.chargebee.com/checkout-portal-docs/cbportal-api-ref.html#opensection
            subscriptionId: ''
          }
        },
        {
          loaded: () => {
            this.logEvent('cb loaded');
          },
          close: () => {
            this.logEvent('cb close');
          },
          visit: (section: string) => {
            this.logEvent('cb visit', section);
          },

          paymentSourceAdd: (e: any) => {
            this.logEvent('paymentSourceAdd', e);
          },
          paymentSourceUpdate: (e: any) => {
            this.logEvent('paymentSourceUpdate', e);
          },
          paymentSourceRemove: (e: any) => {
            this.logEvent('paymentSourceRemove', e);
          },

          subscriptionChanged: (e: PortalSubscriptionEvent) => {
            this.logEvent('subscriptionChanged', e);
          },
          subscriptionCustomFieldsChanged: (e: PortalSubscriptionEvent) => {
            this.logEvent('subscriptionCustomFieldsChanged', e);
          },
          subscriptionCancelled: (e: PortalSubscriptionEvent) => {
            this.logEvent('subscriptionCancelled', e);
          },
          subscriptionResumed: (e: PortalSubscriptionEvent) => {
            this.logEvent('subscriptionResumed', e);
          },
          subscriptionPaused: (e: PortalSubscriptionEvent) => {
            this.logEvent('subscriptionPaused', e);
          },
          scheduledPauseRemoved: (e: PortalSubscriptionEvent) => {
            this.logEvent('scheduledPauseRemoved', e);
          },
          scheduledCancellationRemoved: (e: PortalSubscriptionEvent) => {
            this.logEvent('scheduledCancellationRemoved', e);
          },
          subscriptionReactivated: (e: PortalSubscriptionEvent) => {
            this.logEvent('subscriptionReactivated', e);
          }
        }
      );
    }
  }

  public getSession(): Observable<PortalSessionResponse> {
    return this.http
      .post<PortalSessionResponse>(
        `${environment.apiEndpoint}/v1/portal-sessions`,
        null
      )
      .pipe(
        tap((s: PortalSessionResponse) => {
          this.setSession(JSON.parse(s.json));
        })
      );
  }

  private logEvent(event: string, data: any = ''): void {
    this.logger.info('SubscriptionService', `${event}, ${data}`);
  }

  private setSessionRefreshTimer() {
    if (this.session) {
      const refreshTime: number =
        1e3 * this.session.expires_at - new Date().getTime() - 1e3; // refresh 1 second before expiration

      // drop timer if it was pending
      if (this.refreshTimer !== -1) {
        window.clearTimeout(this.refreshTimer);
      }
      // start timer
      this.refreshTimer = window.setTimeout(() => {
        this.getSession().subscribe();
        this.refreshTimer = -1;
      }, refreshTime);
    }
  }

  private setSession(s: PortalSession) {
    this.session = { ...s };
    this.instance.setPortalSession(() => Promise.resolve(s));
    this.setSessionRefreshTimer();
  }
}
