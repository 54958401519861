import { ENVIRONMENT_INITIALIZER, EnvironmentProviders, inject, Provider } from '@angular/core';

import { LanguageDetectionService } from './language-detection.service';

export function provideLanguageDetection(): (Provider | EnvironmentProviders)[] {
  return [
    {
      provide: ENVIRONMENT_INITIALIZER,
      useValue: () => inject(LanguageDetectionService),
      multi: true,
    },
  ];
}
