import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';

import { AuthService } from './auth.service';

const MISSING_TOKEN_ERROR = 'agent-errors.token-missing';
const INVALID_TOKEN_ERROR = 'agent-errors.invalid-token';
export const iframeAuthGuard: CanActivateFn = (route) => {
  const router = inject(Router);
  const authService = inject(AuthService);

  return new Promise<boolean>((resolve) => {
    const token = route.queryParamMap.get('token');

    if (token) {
      const isTokenExpired = authService.isTokenExpired(token);
      if (!isTokenExpired) {
        authService.setToken(token);
        resolve(true);
      } else {
        router.navigate(['/error-page', { message: INVALID_TOKEN_ERROR }]);
        resolve(false);
      }
    } else {
      router.navigate(['/error-page', { message: MISSING_TOKEN_ERROR }]);
      resolve(false);
    }
  });
};
